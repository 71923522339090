@import '../../styles/variables';

.logoWrapper {
  a {
    text-decoration: none;
    color: $primary_main;
  }
}

.logo {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 0px;
  @media screen and (max-width: $screen-md) {
    margin-bottom: 0px;
  }
  img {
    width: 35px;
  }
  .companyName {
    margin-left: 10px;
    font-weight: bold;
    text-transform: uppercase;
    &.hidden {
      display: none;
    }
  }
  @media screen and (max-width: $screen-md) {
    justify-content: center;
  }
}

.textLogo {
 height: 40px;
}
