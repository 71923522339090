.nameWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  .label {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    &.hidden {
      display: none;
    }
  }
}

.root {
  [class^='simplebar-content-wrapper'] {
    overflow: hidden !important;
  }
}
