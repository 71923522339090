// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

$screen-sm: 600px;
$screen-md: 900px;
$screen-lg: 1200px;
$screen-xl: 1536px;

$header_mobile_height: 64px;
$header_main_desktop_height: 88px;
$header_dashboard_desktop_height: 92px;
$header_dashboard_desktop_offset_height: 60px;

$navbar_base_width: 260px;
$navbar_dashboard_width: 280px;
$navbar_dashboard_collapse_width: 88px;
$navbar_dashboard_item_root_height: 48px;
$navbar_dashboard_item_sub_height: 40px;
$navbar_dashboard_item_horizontal_height: 32px;
$header_height: 40px;
$icon_navbar_item: 22px;
$icon_navbar_item_horizontal: 20px;

$right_size_background: '../../static/right-side-bg.png';

$primary_lighter: #c9e6ed;
$primary_light: #92d4e5;
$primary_main: #C69877;
$primary_dark: #12111E;
$primary_darker: #57a0b2;
$grey: #3d3935;
$light_grey: #f2f1ef;
$white: #FFF;

$font_11: '11px';
$font_13: '11px';