@import '../../../../styles/variables';

.toolbar {
  background: $primary_main;
  min-height: $header_height;
  height: $header_height;
  padding-left: 20px;
  padding-right: 20px;
}

.language {
  width: 25px;
  height: 25px;

  &.active {
    font-weight: bold;
    border-bottom: 1px solid #FFF;
    * {
      font-weight: bold;
    }
  }
}